import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Layout from '@/components/Base/Layout';
import Container from '@/components/Base/Container/PageContainer';
import SEO from '@/components/Base/seo';
import Podcast from '@/components/Podcasts';

const Heading = styled.h1`
  font-size: 12rem;
  -webkit-text-stroke: 1px ${({ theme }) => theme.colors.blackText};
  color: ${({ theme }) => theme.colors.mainText};
  text-shadow: 0px 0px 0 ${({ theme }) => theme.colors.blackText}, -1px -1px 0 ${({ theme }) => theme.colors.blackText},
    1px -1px 0 ${({ theme }) => theme.colors.blackText}, -1px 1px 0 ${({ theme }) => theme.colors.blackText},
    1px 1px 0 ${({ theme }) => theme.colors.blackText};
  font-weight: 600;
  padding-bottom: 0.5rem;
  text-align: center;
  text-transform: uppercase;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    font-size: 3.5rem;
  }
  @media ${({ theme }) => theme.breakpoints.tablet} {
    font-size: 8rem;
  }
  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 10rem;
  }
`;

export default function Podcasts({ pageContext: { podcasts } }) {
  return (
    <Layout>
      <SEO title="Подкасты" keywords={['Подкасты']} slug="podcasts" />
      <Heading>ПОДКАСТЫ</Heading>
      <Container>
        {!!podcasts?.length && podcasts.map(podcast => <Podcast podcast={podcast} key={podcast.id} />)}
      </Container>
    </Layout>
  );
}

Podcasts.propTypes = {
  pageContext: PropTypes.any.isRequired,
};
