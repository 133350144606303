import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import SoundcloudIcon from 'emotion-icons/fa-brands/Soundcloud';
import GoogleColorIcon from 'emotion-icons/simpleIcons/GooglePodcasts';
import ITunesIcon from 'emotion-icons/fa-brands/Itunes';
import YandexIcon from 'emotion-icons/fa-brands/Yandex';

const PodcastContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
`;

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  align-items: flex-start;
`;

const Description = styled.p`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 1.8rem;
  width: 100%;
  line-height: 3rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    font-size: 1.4rem;
  }
  @media ${({ theme }) => theme.breakpoints.tablet} {
    font-size: 1.5rem;
  }
  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 1.6rem;
  }
`;

const Listen = styled.p`
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 700;
  margin-right: 1rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    font-size: 1.8rem;
  }
  @media ${({ theme }) => theme.breakpoints.tablet} {
    font-size: 1.7rem;
  }
  @media ${({ theme }) => theme.breakpoints.desktop} {
    font-size: 1.8rem;
  }
`;

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Heading = styled.h2`
  margin-bottom: 0.5rem;
  font-size: 1rem;
  width: 100%;
  text-align: center;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    font-size: 2rem;
  }
  @media ${({ theme }) => theme.breakpoints.tablet} {
    font-size: 2.5rem;
  }
  @media ${({ theme }) => theme.breakpoints.pc} {
    font-size: 3rem;
  }
`;

const GoogleIcon = styled(GoogleColorIcon)`
  color: ${({ theme }) => theme.colors.main};
  :hover {
    opacity: 0.7;
    color: ${({ theme }) => theme.colors.main};
  }
`;

const OuterLink = styled.a`
  margin-right: 1rem;
  width: 2.4rem;
  height: 2.4rem;
  color: ${({ theme }) => theme.colors.main};
  :hover {
    opacity: 0.7;
    color: ${({ theme }) => theme.colors.main};
  }
`;

const OuterLinkLast = styled.a`
  width: 2.4rem;
  height: 2.4rem;
  color: ${({ theme }) => theme.colors.main};
  :hover {
    opacity: 0.7;
    color: ${({ theme }) => theme.colors.main};
  }
`;

export default function Podcast({ podcast }) {
  const { src, iTunes, Google, Soundcloud, title, Yandex, description } = podcast;
  return (
    <PodcastContainer>
      <HeadingContainer>
        <Heading>{title}</Heading>
        <Description>{description}</Description>
        <IconContainer>
          <Listen>Слушать:</Listen>
          <OuterLink href={Soundcloud}>
            <SoundcloudIcon />
          </OuterLink>
          <OuterLink href={Google}>
            <GoogleIcon />
          </OuterLink>
          <OuterLink href={iTunes}>
            <ITunesIcon />
          </OuterLink>
          <OuterLinkLast href={Yandex}>
            <YandexIcon />
          </OuterLinkLast>
        </IconContainer>
      </HeadingContainer>
      <iframe
        width="100%"
        height="450"
        title="Подкаст"
        scrolling="no"
        frameBorder="no"
        allow="autoplay"
        src={`${src}&color=%23182837&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true`}
      />
    </PodcastContainer>
  );
}

Podcast.propTypes = {
  podcast: PropTypes.any.isRequired,
};
